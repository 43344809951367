import globalCSS from '../index.module.scss';
import { useCallback } from 'react';
import { useApp } from 'packages/framework/hooks/App/useApp';

import { HeadProvider, StoreTitle } from 'packages/framework/components/Head';
import Main from 'components/MainGourmet';
import ToastContainer from 'packages/framework/components/ToastContainer';
import Icon from 'packages/framework/components/Icon';

import AlertCircleIcon from 'react-feather/dist/icons/alert-circle';
import CloudOffIcon from 'react-feather/dist/icons/cloud-off';
import WifiIcon from 'react-feather/dist/icons/wifi';
import { useToasts } from 'packages/framework/Toasts';
import Mask from 'packages/framework/components/Mask';

const OnlineIcon = <Icon src={WifiIcon} attrs={{ width: 18 }} />;
const OfflineIcon = <Icon src={CloudOffIcon} attrs={{ width: 18 }} />;
const ErrorIcon = <Icon src={AlertCircleIcon} attrs={{ width: 18 }} />;

const formatMessage = (a: any) => {
  return '';
};
const addToast = '';
const App = props => {
  const { markErrorHandled, renderError, unhandledErrors, children } = props;
  // const { formatMessage } = useIntl();
  const [, { addToast }] = useToasts();
  // useDelayedTransition();

  const ERROR_MESSAGE = formatMessage({
    id: 'app.errorUnexpected',
    defaultMessage: 'Sorry! An unexpected error occurred.',
  });

  const handleIsOffline = useCallback(() => {
    addToast({
      type: 'error',
      icon: OfflineIcon,
      message: formatMessage({
        id: 'app.errorOffline',
        defaultMessage: 'You are offline. Some features may be unavailable.',
      }),
      timeout: 3000,
    });
  }, [addToast, formatMessage]);

  const handleIsOnline = useCallback(() => {
    addToast({
      type: 'info',
      icon: OnlineIcon,
      message: formatMessage({
        id: 'app.infoOnline',
        defaultMessage: 'You are online.',
      }),
      timeout: 3000,
    });
  }, [addToast, formatMessage]);

  const handleError = useCallback(
    (error, id, loc, handleDismissError) => {
      const errorToastProps = {
        icon: ErrorIcon,
        message: `${ERROR_MESSAGE}\nDebug: ${id} ${loc}`,
        onDismiss: remove => {
          handleDismissError();
          remove();
        },
        timeout: 15000,
        type: 'error',
      };

      addToast(errorToastProps);
    },
    [ERROR_MESSAGE, addToast],
  );

  const talonProps = useApp({
    handleError,
    handleIsOffline,
    handleIsOnline,
    markErrorHandled,
    renderError,
    unhandledErrors,
  });

  const { hasOverlay, handleCloseDrawer } = talonProps;

  if (renderError) {
    return (
      <HeadProvider>
        <StoreTitle />
        <Main isMasked={true} />
        <Mask isActive={true} />
        <ToastContainer />
      </HeadProvider>
    );
  }

  return (
    <HeadProvider>
      <StoreTitle />
      <Main isMasked={hasOverlay}>{children}</Main>
      <Mask
        isActive={hasOverlay}
        dismiss={handleCloseDrawer}
        data-cy="App-Mask-button"
      />
      {/* <Navigation /> */}
      <ToastContainer />
    </HeadProvider>
  );
};

// App.propTypes = {
//     markErrorHandled: func.isRequired,
//     renderError: shape({
//         stack: string
//     }),
//     unhandledErrors: array
// };

App.globalCSS = globalCSS;

export default App;
